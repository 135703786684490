@import "../../../scss/variables";

.footer {
    position: relative;
    bottom: 0;
    width: 100%;
    &-content {
        min-height: 190px;
        background-color: $primary-color;
        color: #ffffff;   
        @media screen and (max-width: 991px) {
            padding-top: 24px;
            padding-bottom: 20px;
        }
        @media screen and (max-width: 767px) {
            padding-top: 40px;
            padding-bottom: 20px;
        }
        padding-top: 65px;
        padding-bottom: 55px;
        .logo {
            img {
                width: 170px;
            }
            @media screen and (max-width: 991px) {
                margin-bottom: 34px;
            }
        }
        .row {
            padding-left: 45px;
            padding-right: 25px;
            @media screen and (max-width: 991px) {
                padding-left: 39px;
                padding-right: 12px;
            }
        }
        .mobile-col {
            @media screen and (max-width: 576px) {
                margin-bottom: 20px;
            }
        }   
    }

    &-follow {
        border-top: 1px solid #d8d8d8;
        margin-left: 30px;
        margin-right: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 26px;
        .footer-text {
            padding-bottom: unset;
            margin-right: 10px;
        }
    }

    &-bottom {
        height: 50px;
        @media screen and (max-width: 567px) {
            height: 99px;
        }
        background-color: $footer-bottom;
        padding-left: 45px;
        padding-right: 45px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
            font-family: Montserrat-Regular;
            font-size: 12px;
            color: #ffffff;
            .bold {
                font-family: Montserrat-Bold;
            }
        }
        .powered {
            @media screen and (max-width: 567px) {
                padding-bottom: 10px;
            }
        }
    }

    &-link {
        font-family: Montserrat-Bold;
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 0.82px;
        color: #ffffff;
        padding-bottom: 20px;
        cursor: pointer;
    }

    &-text {
        font-family: Montserrat-Regular;
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: 0.52px;
        color: #ffffff;
        padding-bottom: 11px;
        .bold {
            font-family: Montserrat-Bold;
            text-transform: uppercase;
            font-size: 14px;
            letter-spacing: 0.6px;
            color: #ffffff;
        }
    }

    &-apps {
        padding-right: 10px;
        padding-bottom: 12px;
        @media screen and (max-width: 767px) {
            display: flex;
            justify-content: center;
        }
        @media screen and (max-width: 576px) {
            display: unset;
            justify-content: unset;
        }
        button {
            @media screen and (max-width: 567px) {
                width: 100%;
            }
            @media screen and (max-width: 767px) {
                margin-right: 10px;
            }
        }
        a {
            text-decoration: none;
        }
    }

    &-social {
        padding-top: 15px;
        align-items: center;
        @media screen and (max-width: 767px) {
            justify-content: center;
        }
        img {
            width: 27px;
            height: unset;
            max-width: 100%;
        }
        .icon {
            margin-left: 20px;
            margin-right: 15px;
        }
        &-icon {
            margin-right: 20px;
        }
    }
}

