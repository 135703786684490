@import "../../../../scss/variables";

.header {
    height: 129px;
    &-wrapper {
        flex-direction: column;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 102;
        background-color: #fafafa;
        max-width: 2000px;
        margin: 0 auto;
        box-shadow: 0 1px 14px 0 rgba(40, 40, 40, 0.5);
        // outline: 2px solid $secondary-color;
        // border-bottom: 2px solid $primary-color;
    }

    &-top {
        padding-left: 50px;
        padding-right: 50px;
        height: 54px;
        background-image: linear-gradient(87deg, #25378d 0%, #1a71a5 100%);
        color: #ffffff;
        align-items: center;

        span {
            font-family: Montserrat-Bold;
            font-size: 12px;
            letter-spacing: 0.1px;
            text-transform: uppercase;
            &.status {
                text-transform: lowercase;
                font-family: Montserrat-ExtraBold;
            }
        }
        .pin {
            img {
                width: 153px;
                height: 25.5px;
            }
        }
        .order {
            flex-grow: 1;
            text-align: center;
            font-family: Montserrat-Regular;
            font-size: 12px;
            line-height: 1.83;
            letter-spacing: 0.48px;
            color: #ffffff;
            &-link {
                text-decoration: none;
                span {
                    font-size: 14px;
                    font-family: Montserrat-Bold;
                    letter-spacing: 0.56px;
                    color: #ffffff;
                }
            }
        }
        

        .user {
            display: flex;
            justify-content: flex-end;
            svg {
                cursor: pointer;
                width: 20px;
                height: 20px;
            }
            &-logged-container {
                cursor: pointer;
                width: 22px;
                height: 22px;
                background-color: #ffffff;
                border-radius: 50%;
                position: relative;
            }
            &-logged {
                border: solid 1px #3c3c3b;
                border-radius: 50%;
                font-family: Montserrat-SemiBold;
                font-size: 9px;
                letter-spacing: -0.51px;
                width: 18px;
                height: 18px;
                top: 50%;
                left: 50%;
                margin: -9px 0px 0px -9px;
                position: absolute;
                color: #333333;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

    }

    &-bottom {
        padding-left: 50px;
        padding-right: 40px;
        height: 75px;
        background-color: #ffffff;
        align-items: center;
        .logo {
            width: 170px;
            width: 120px;
            img {
                max-width: 100%;
                width: auto;
            }
            // span {
            //     font-family: Montserrat-Bold;
            //     text-transform: uppercase;
            //     font-size: 37px;
            //     font-weight: 900;
            //     font-style: italic;
            //     letter-spacing: 1.35px;
            //     color: #333333;
            // }
        }
        
        .search {
            display: flex;
            padding-bottom: 6px;
            form {
                display: flex;
                width: 100%;
                margin-top: unset;
            }
            input {
                height: 45px;
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
                border: 1px solid #d8d8d8;
                width: 100%;
                border-right: none;
                padding-left: 11px;
                padding-right: 5px;
                &::placeholder {
                    letter-spacing: 0.12px;
                    font-size: 14px;
                    color: $top-header-search;
                }
            }
            button {
                height: 45px;
                border-radius: unset;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
                width: 99px;
                border: none;
                margin-bottom: unset;
                span {
                    padding: unset;
                }
            }
        }
        .user {
            svg {
                cursor: pointer;
                width: 20px;
                height: 20px;
            }
            &-logged {
                cursor: pointer;
                border: solid 1px #3c3c3b;
                border-radius: 50%;
                font-family: Montserrat-SemiBold;
                font-size: 9px;
                letter-spacing: -0.51px;
                width: 22px;
                height: 22px;
                position: absolute;
                color: #333333;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        .actions {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            svg {
                cursor: pointer;
                width: 22px;
                height: 22px;
            }
            // svg path {
            //     fill: #333333!important;
            // }
        }
    }
}

