@import '../../../scss/_variables.scss';

.card-product {
    width: 319px;
    max-width: 319px;
    // height: 409px;
    border-radius: 8px;
    box-shadow: $card-shadow;
    background-color: #ffffff;
    padding-bottom: 20px;
    margin: 0 10px 50px;
    @media screen and (min-width: 351px) and (max-width: 361px) {
        max-width: 165px;
        margin: 0 5px 50px;
    }
    @media screen and (min-width: 361px) and (max-width: 390px) {
        max-width: 170px;
        margin: 0 5px 50px;
    }
    @media screen and (min-width: 390px) and (max-width: 580px) {
        max-width: 220px;
        margin: 0 10px 50px;
    }
    @media screen and (min-width: 580px) and (max-width: 678px) {
        max-width: 270px;
        margin: 0 10px 50px;
    }
    

    &-image {
        width: 100%;
        max-height: 273px;
        text-align: center;
        background-color: #e4eaf0;
        object-fit: contain;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

   
    &-details {
        margin-top: 20px;
        padding-right: 20px;
        padding-left: 20px;
        height: 36px;
        span {
            font-stretch: normal;
            font-style: normal;
            display: block;
            display: -webkit-box;
            text-overflow: ellipsis;
            overflow: hidden;
            word-wrap: break-word;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-family: Montserrat-Bold;
            font-size: 14px;
            letter-spacing: 0.6px;
            color: #333333;
        }
    }
    &-promo {
        margin-top: 10px;
        padding-left: 20px;
        padding-right: 20px;
        @media screen and (max-width: 576px) {
            padding-right: 10px;
        }
        font-family: Montserrat-Medium;
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: normal;
        color: #f80000;
    }
    &-prices {
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 11px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .strike {
            height: 18px;
            font-family: Montserrat-Regular;
            font-size: 14px;
            letter-spacing: 1.22px;
            color: #7a7a7a;
        }
        .discount {
            font-family: Montserrat-ExtraBold;
            font-size: 14px;
            color: #f80000;
            @media screen and (max-width: 576px) {
                display: none;
            }
        }
        .netTotal {
            font-family: Montserrat-Bold;
            font-size: 14px;
            text-align: right;
            color: $secondary-color;
        }
    }
}