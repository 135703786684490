@import "../../../../scss/variables";

.address-popup {
    max-width: 721px;
    @media screen and (max-width: "576px") {
        width: unset;
    }
    border-radius: 15px;
    background-color: #ffffff;
    .close {
        width: 16px;
        height: 16px;
        padding-right: 20px;
        cursor: pointer;
        color: #212528;
        opacity: unset;
    }
    &-form {
        padding-left: 45px;
        padding-right: 45px;
        @media screen and (max-width: "576px") {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    &-header {
        text-align: center;
        font-family: Montserrat-Bold;
        font-size: 18px;
        letter-spacing: 0.77px;
        color: #333333;
        text-transform: uppercase;
        padding-top: 34px;
        padding-bottom: 34px;
    }
    &-content {
        .popup-row {
            padding-bottom: 30px;
        }
    }
    &-actions {
        padding-top: 5px;
        height: 70px;
        text-align: center;
        button {
            width: 200px;
            @media screen and (max-width: "576px") {
                width: unset;
            }
        }
        .delete {
            border-radius: 8px;
            border: solid 2px #dd0202;
            background-color: #ffffff;
            margin-right: 21px;
            span {
                color: #dd0202;
            }
            &:hover {
                background-color: unset;
            }
        }
    }
}