@import "../../scss/variables";

.signup {
    &-banner {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 52px;
        .text {
            max-width: 476px;
            @media screen and (max-width: "576px") {
                max-width: 350px;
            }
        }
        span {
            font-family: Montserrat-Bold;
            font-size: 36px;
            letter-spacing: 1.54px;
            text-align: center;
            color: #ffffff;
            @media screen and (max-width: "576px") {
                font-size: 26px;
            }
        }
    }
    &-facebook {
        text-align: center;
    }
    &-content {
        max-width: 889px;
        width: 100%;
        margin: 0 auto;
        .facebook-cta {
            margin-top: 29px;
            margin-bottom: 36px;
        }
    }
    
    &-card {
        max-width: 757px;
        width: 100%;
        @media screen and (max-width: "991px") {
            width: 90%;
            padding-right: 20px;
            padding-left: 20px;
        }
        margin: 0 auto;
        padding-top: 21px;
        padding-right: 57px;
        padding-left: 42px;
        padding-bottom: 42px;
        .card-title {
            font-family: Montserrat-Bold;
            font-size: 18px;
            line-height: 1.33;
            letter-spacing: 0.49px;
            color: #000000;
        }
        hr {
            height: 1px;
            background-color: $primary-color;
            margin-top: 18px;
            margin-bottom: 25px;
        }
        .form-group {
            margin-bottom: 2rem;
        }
        border-radius: 8px;
        box-shadow: 0 0 11px 0 rgba(101, 179, 46, 0.26);
        background-color: #ffffff;
    }
    &-actions {
        margin-top: 32px;
        margin-bottom: 40px;
        .cta {
            width: 300px;
        }
    }
    .custom-control {
        min-height: unset;
    }
    .custom-control-label {
        font-family: Montserrat-Regular;
        font-size: 14px;
        letter-spacing: 0.6px;
        color: #333333;
        padding-left: 10px;
        margin-bottom: 20px;
        &::after, &::before {
            border-radius: 4px;
            border: solid 1px $primary-color;
            cursor: pointer;
        }
    }
}

.separator {
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 42px;
    span {
        font-family: Montserrat-Regular;
        font-size: 12px;
        letter-spacing: 0.51px;
        color: #333333;
    }
}
.separator::before, .separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid $primary-color;
}
.separator::before {
    margin-right: 16px;
}
.separator::after {
    margin-left: 16px;
}