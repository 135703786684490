@import "../../../scss/variables";

.cart-item {
    display: flex;
    width: 100%;
    padding-right: 40px;
    @media screen and (max-width: "576px") {
        padding-right: unset;
    }
    &-image {
        img {
            width: 116px;
            height: 150px;
            @media screen and (max-width: "576px") {
                width: 118px;
                height: 200px;
            }
            margin-right: 14px;
        }
    }
    &-info {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .layout {
            display: flex;
            justify-content: space-between;
            @media screen and (max-width: "576px") {
                flex-direction: column;
            }
        }
        .action-layout {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media screen and (max-width: "576px") {
                flex-direction: column-reverse;
                align-items: unset;
            }
        }
        select {
            width: 94px;
            padding: 7px 16px 7px 24px;
            border-radius: 8px;
            border: solid 1px #333333;
            background-color: #ffffff;
            @media screen and (max-width: "576px") {
                width: 78px;
                padding: 5px 10px 5px 19px;
                border-radius: 4px;
            }
        }
    }
    &-promo {
        font-family: Montserrat-Bold;
        text-transform: uppercase;
        font-size: 10px;
        line-height: 2.2;
        letter-spacing: 0.15px;
        color: #f80000;
        @media screen and (max-width: "576px") {
            display: none;
        }
    }
    &-description {
        font-family: Montserrat-Bold;
        font-size: 14px;
        letter-spacing: 0.6px;
        color: #333333;
        margin-bottom: 20px;
    }
    &-variants {
        .variant {
            font-family: Montserrat-Regular;
            font-size: 12px;
            letter-spacing: 0.3px;
            color: #7a7a7a;
        }
        .option {
            font-family: Montserrat-Bold;
            font-size: 12px;
            letter-spacing: 0.3px;
            font-weight: bold;
            color: #333333;
        }
    }
    &-remove {
        font-family: Montserrat-Regular;
        font-size: 12px;
        letter-spacing: 0.3px;
        color: #838383;
        margin-top: 15px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media screen and (max-width: "576px") {
            justify-content: unset;
        }
    }
    &-prices {
        margin-top: 15px;
        font-family: Montserrat-Bold;
        font-size: 16px;
        color: #333333;
        @media screen and (max-width: "576px") {
            font-size: 14px;
        }
    }
}