@import "../../../scss/variables";

.article {
    color: #000;
    font-family: FuturaStd-Book;
    font-size: 14px;
    margin-bottom: 10px;   

    @media screen and (min-width: 576px) {
        margin-bottom: 20px;
    }

    .col {
        position: relative;
    }

    p {
        margin: 0;
        padding: 0;
    }
}

.article__image {
    height: 65px;
    width: 65px;
    background: #fff;
    position: relative;
    img {
        left: 50%;
        max-height: 65px;
        max-width: 65px;
        position: absolute;
        top: 50%;
        -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
    }
}

.article__centerer {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.article__info {
    margin-top: 15px;
    @media screen and (min-width: 576px) {
        margin-top: 0;
    }
    @media screen and (max-width: 576px) {
        margin-top: 5px;
        .article__promo {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
    @media screen and (min-width: 992px) {
        p {
            margin-left: 14px;
        }
    }
}

.article__name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    font-family: Montserrat-Bold;
    font-size: 12px;
    letter-spacing: 0.51px;
    color: #333333;
}

.two-line {
    @media screen and (min-width: 576px) {
        height: 30px;
    }
}

.article__price {
    margin-top: 15px;
    span {
        font-family: Montserrat-Bold;
        font-size: 12px;
        color: #333333;
    }
    @media screen and (min-width: 576px) {
        margin-top: 0;
    }
    @media screen and (max-width: 576px) {
        margin-top: 5px;
        span {
            display: block;
        }
    }
}

.article__amount {
    span {
        font-family: Montserrat-Medium;
        font-size: 14px;
    }
}

.article__total {
    font-family: Montserrat-Bold;
    font-size: 14px;
    color: $primary-color;
}

.article__remove {
    @media screen and (max-width: 576px) {
        margin-top: 5px;
        padding-left: 40px;
    }
    button {
        width: 15px;
        overflow: hidden;
        float: left;
        @media screen and (min-width: 576px) {
            float: none;
        }
    }
}