@import "../../../scss/variables";

.modal-dialog {
    max-width: 701px!important;
    width: 701px;
}

.success-popup {    
    .modal-content {
        padding: 71px 9px 59px 19px;
        border: none;
        border-radius: 10px;
        box-shadow: 0 0 49px 0 rgba(0, 0, 0, 0.31);
        background-color: #ffffff;
        max-width: 701px!important;
        width: 701px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: "991px") {
            width: unset;
        }
        @media screen and (max-width: "576px") {
            width: unset;
            padding: 50px 9px 39px 19px;
        }
    }

    &-title {
        font-family: Montserrat-Bold;
        font-size: 18px;
        letter-spacing: 0.77px;
        color: #333333;
        margin-top: 15px;
        margin-bottom: 14px;
        text-align: center;
    }
    &-text {
        font-family: Montserrat-Regular;
        font-size: 16px;
        line-height: 1.25;
        letter-spacing: 1.4px;
        color: #333333;
        margin-bottom: 3px;
        text-align: center;
        b {
            font-family: Montserrat-Bold;
        }
    }
    .action {
        margin-top: 20px;
    }
}