.smartlist-item {
    color: #000;
    font-family: FuturaStd-Book;
    font-size: 14px;
    margin-bottom: 30px;   

    @media screen and (min-width: 576px) {
        margin-bottom: 20px;
    }

    .col {
        position: relative;
    }

    p {
        margin: 0;
        padding: 0;
    }
}

.smartlist-item__image {
    height: 65px;
    width: 65px;
    background: #fff;
    position: relative;
    img {
        left: 50%;
        max-height: 50px;
        max-width: 50px;
        position: absolute;
        top: 50%;
        -webkit-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
    }
}

.smartlist-item__centerer {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.smartlist-item__info {
    margin-top: 15px;
    @media screen and (min-width: 576px) {
        margin-top: 0;
    }
    @media screen and (min-width: 992px) {
        p {
            margin-left: 14px;
        }
    }
}

.smartlist-item__name {
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
}

.smartlist-item__price {
    margin-top: 15px;
    @media screen and (min-width: 576px) {
        margin-top: 0;
    }
    @media screen and (max-width: 576px) {
        span {
            display: block;
        }
    }
}

.smartlist-item__actions {
    height: 83px;
    position: absolute;
    right: 9px;
    width: 15px;

    @media screen and (min-width: 992px) {
        margin-right: -50px;
        position: relative;
        width: 40px;
    }

    @media screen and (min-width: 576px) {
        height: 65px;
    }

    @media screen and (max-width: 576px) {
        .smartlist-item__centerer {
            top: 100%!important;
            -webkit-transform: translateY(-100%);
            -ms-transform: translateY(-100%);
            transform: translateY(-100%);
        }
    }
}

.smartlist-item__remove {
    width: 15px;
    overflow: hidden;
    float: left;
}