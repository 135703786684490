@import "../../../../scss/variables";

.header-item {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-left: 10px;
    @media screen and (max-width: "576px") {
        margin-left: 3px;
    }
    &-actived {
        background-color: $secondary-color;
        .header-item-counts {
            background-color: #9e9e9e;
        }
        .header-item-total {
            background-color: #9e9e9e;
        }
        .user-logged {
            color: #ffffff!important;
            border-color: #ffffff!important;
        }
        svg path {
            fill: #ffffff;
        }
        svg {
            // stroke: #ffffff;
            fill: #ffffff;
        }
    }
    &-container {
        position: relative;
    }
    &-counts {
        position: absolute;
        background-color: $secondary-color;
        width: 20px;
        height: 20px;
        align-items: center;
        display: flex;
        justify-content: center;
        border-radius: 50%;
        top: -5px;
        right: 0px;
        span {
            font-family: Montserrat-Bold;
            font-size: 10px;
            letter-spacing: 0.43px;
            text-align: center;
            color: #ffffff;
        }
    }
    &-total {
        position: absolute;
        background-color: $secondary-color;
        width: 56px;
        height: 15px;
        border-radius: 10px;
        align-items: center;
        display: flex;
        justify-content: center;
        bottom: -5px;
        span {
            font-family: Montserrat-SemiBold;
            font-size: 10px;
            letter-spacing: 0.43px;
            text-align: center;
            color: #ffffff;
        }
    }
}