@import "../../scss/variables";

.category-slider-wrapper {
    height: 140px;
    padding: 20px;
    @media screen and (max-width: 576px) {
        height: 112px;
    }
    overflow: hidden;
    .slick-slide {
        margin-left: 10px;
        margin-right: 10px;
    }

    .slick-list {
        display: flex;
        margin: 0 auto;
        max-width: calc(100vw - 50px);
        @media screen and (min-width: 992px) {
            max-width: calc(100vw - 150px);   
        }
        height: 100%;
    }

    .slick-slider {
        height: 100%;
    }

    .slick-track {
        display: inline-flex;
        margin: auto 0
    }

    .slick-prev{
        display: flex !important;
        margin: auto;
        background: white;
        left: 0px;
        width: 50px;
        height: 100%;
        z-index: 10;
        &:hover {
            background: white;
            opacity: 0.6;
        }
        &:before {
            color: $secondary-color;
            font-size: 35px;
            background-color: white;
            margin: auto;
            opacity: 1;
        }
    }
  
    .slick-next {
        display: flex !important;;
        margin: auto;
        background: white;
        right: 0px;
        width: 50px;
        height: 100%;
        z-index: 10;
        &:hover {
            background: white;
            opacity: 0.6;
        }
        &:before {
            color: $secondary-color;
            font-size: 35px;
            background-color: white;
            margin: auto;
            opacity: 1;
        }
  }

    .category-slider {
        height: 137px;
        display: flex;
        align-items: center;
        overflow-x: auto;
        &-card {
            display: inline-flex !important;
            justify-content: space-between;
            align-items: center;
            height: 45px;
            border-radius: 9px;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
            background-color: #ffffff;
            transition: 0.3s;
            cursor: pointer;
            &:hover .category-slider-content {
                color: #ffffff;
            }
            &:hover .category-slider-circle {
                background-color: #ffffff
            }
            &:hover {
                background-color: $secondary-color;
            }
            &:focus {
                outline: none;
            }
            &:hover svg {
                path {
                    fill: #ffffff;
                }
            }
            svg {
                margin-left: 10px;
                path {
                    fill: $secondary-color;
                }
            }
        }
        &-content {
            font-family: Montserrat-Bold;
            font-size: 14px;;
            letter-spacing: 0.51px;
            color: #333333;
            white-space: nowrap;
            text-transform: uppercase;
            margin-left: 10px;
            margin-right: 10px;
        }
        
        &-circle {
            width: 36px;
            height: 36px;
            margin-left: 10px;
            background-color: $secondary-color;
            border-radius: 50px;
        }
    }
}