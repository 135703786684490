@import "../../../../scss/variables";

.mobile-header {
    // height: 133px;
    height: 110px;
    &-wrapper {
        flex-direction: column;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 102;
        box-shadow: 0 1px 14px 0 rgba(40, 40, 40, 0.5);
        // outline: 2px solid $secondary-color;
        // border-bottom: 2px solid $primary-color;
    }

    &-top {
        height: 45px;
        background-image: linear-gradient(87deg, #25378d 0%, #1a71a5 100%);
        color: #ffffff;
        align-items: center;
        .pin {
            img {    
                width: 153px;
                height: 25.5px;
            }
        }
        .user {
            display: flex;
            justify-content: flex-end;
            svg {
                cursor: pointer;
                width: 20px;
                height: 20px;
            }
            &-logged-container {
                cursor: pointer;
                width: 22px;
                height: 22px;
                background-color: #ffffff;
                border-radius: 50%;
                position: relative;
            }
            &-logged {
                border: solid 1px #3c3c3b;
                border-radius: 50%;
                font-family: Montserrat-SemiBold;
                font-size: 9px;
                letter-spacing: -0.51px;
                width: 18px;
                height: 18px;
                top: 50%;
                left: 50%;
                margin: -9px 0px 0px -9px;
                position: absolute;
                color: #333333;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    &-bottom {
        padding-right: 6px;
        height: 65px;
        background-color: #ffffff;
        border-bottom: 1px solid #edeaea;
        align-items: center;
        .logo {
            // width: 139px;
            width: 90px;
            svg {
                width: 170px;
            }
            img {
                max-width: 100%;
            }
        }
        .icons {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
        .header-item {
            svg {
                width: 20px;
                height: 20px;
            }
        }
    }

    &-search {
        padding-left: 12px;
        padding-right: 16px;
        height: 48px;
        background-color: #ffffff;
        display: flex;
        padding-top: 9px;
        padding-bottom: 9px;
        border-bottom: 1px solid #edeaea;
        &-icon {
            width: 18px;
            height: 18px;
        }

        form {
            display: flex;
            width: 100%;
            margin-top: unset;
        }

        input {
            // -webkit-appearance: none;
            height: 30px;
            padding-left: 8px;
            padding-right: 5px;      
            border-radius: unset;      
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            border: 1px solid #d8d8d8;
            width: 100%;
            border-right: none;
            font-size: 12px;
            &::placeholder {
                letter-spacing: 0.12px;
                font-size: 12px;
                color: $top-header-search;
            }
        }
        button {
            width: 70px;
            height: 30px;
            border-radius: unset;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            span {
                color: #ffffff;
                letter-spacing: 0.87px;
                font-size: 12px;
                font-family: Montserrat-Bold;
                text-transform: uppercase;
                padding-left: unset;
            }
        }
    }
}