@import '../../scss/variables';

.booking {
    padding-left: 50px;
    padding-right: 50px;
    @media screen and (max-width: "576px") {
        padding-left: 20px;
        padding-right: 20px;
    }
    &-container {
        padding-bottom: 40px;
        background: #fbfbfb;
    }
    &-banner {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 188px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 69px;
        @media screen and (max-width: "576px") {
            margin-bottom: 30px;
        }
    }
    &-title {
        font-family: Montserrat-bold;
        letter-spacing: 1.29px;
        text-align: center;
        color: #333333;
        margin-bottom: 30px;
        text-align: center;
        font-size: 30px;
        @media screen and (max-width: "576px") {
            font-size: 24px;
        }
    }
    &-subtitle {
        font-family: Montserrat-Bold;
        letter-spacing: 0.77px;
        color: #333333;
        margin-bottom: 20px;
        font-size: 18px;
        @media screen and (max-width: "576px") {
            font-size: 14px;
        }
    }
    &-view {
        max-width: 888px;
        width: 100%;
        .form-input {
            background-color: unset;
        }
        .form-group {
            margin-bottom: 2rem;
        }
        .cta {
            width: 321px;
            height: 45px;
        }
        .custom-control {
            min-height: 10vh;
        }
    }
    &-select {
        background: none;
        font-size: 14px;
        padding: .375rem .75rem;
        padding-left: 2px;
        display: block;
        width: 100%;
        border: none;
        border-radius: 0;
        line-height: 1.5;
        border-bottom: 1px solid #ced4da;
        font-family: Montserrat-Medium;
        color: #212528; 
    }
    .select-label {
        color: #7a7a7a;
        font-family: Montserrat-Regular;
        font-size: 12px;
        margin-bottom: unset;
        letter-spacing: 0.6px;
    }
    .no-timeslots {
        color: red;
        font-family: Montserrat-Regular;
        font-size: 12px;
        margin-bottom: unset;
        letter-spacing: 0.6px;
    }
    
    .grid_1 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        @media screen and (max-width: "576px") {
            grid-template-columns: 1fr 1fr;
        }
        column-gap: 20px;
        row-gap: 15px;
        margin-top: 20px;
        margin-bottom: 34px;
        button {
            border-radius: 8px;
            border: solid 2px #5b5b5b;
            background-color: #ffffff;
            font-family: Montserrat-Regular;          
            font-size: 14px;
            letter-spacing: 0.1px;
            color: #333333;
            height: 45px;
            &:hover {
                background-color: #333333;
                color: #ffffff;
                font-family: Montserrat-Bold;
            }
        }
        .selected {
            background-color: #333333;
            color: #ffffff;
            font-family: Montserrat-Bold;
        }
    }
    &-notes {
        border-radius: 5px!important;
        border: solid 1px #d8d8d8!important;
        background-color: #fbfbfb!important;
        margin-bottom: 20px;
        font-size: 14px!important;
        letter-spacing: 0.6px;
        color: #212528!important; 
        font-family: Montserrat-Regular;
        padding: 10px;
        &::placeholder {
            color: #bebebe!important;
        }
    }
}